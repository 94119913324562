










































































































































































































































































import { defineComponent } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'

import SelectInputRow from '../SelectInputRow/index.vue'
import ColorCircle from '../ColorCircle/index.vue'

interface SetUp {
  reset: () => void
  validate: () => Promise<boolean>
  clickRow: (row: string) => void
}

const CreateCommonForm = defineComponent({
  components: {
    SelectInputRow,
    ColorCircle
  },
  props: {
    detail: {
      type: Object,
      required: true
    },
    rows: {
      type: Array,
      required: true
    },
    requiredRows: {
      type: Array,
      required: false,
      default: () => []
    },
    disabledRows: {
      type: Array,
      required: false,
      default: () => []
    },
    thisPage: {
      type: String,
      required: false,
      default: null
    },
    isShowCheck: {
      type: Boolean,
      required: false,
      default: false
    },
    enableShortcut: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { root, refs, emit }): SetUp {
    const { $toast } = root

    const clickRow = (row: string): void => {
      if (!props.disabledRows.includes(row)) {
        emit('on-click', row)
      }
    }

    const validate = async (): Promise<boolean> => {
      const observer = refs.observer as InstanceType<typeof ValidationObserver>
      const valid = await observer.validate()
      if (!valid) {
        let errorMess: Array<string> = []
        Object.keys(observer.errors).forEach((key) => {
          errorMess = [...errorMess, ...observer.errors[key]]
        })
        $toast.error(errorMess.join(',\n'))
        return false
      }
      return true
    }

    const reset = (): void => {
      const observer = refs.observer as InstanceType<typeof ValidationObserver>
      observer.reset()
    }

    return {
      reset,
      validate,
      clickRow
    }
  }
})

export default CreateCommonForm
