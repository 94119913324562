



























































import {
  defineComponent,
  ref,
  Ref,
  watch,
  computed,
  ComputedRef,
  toRefs
} from '@vue/composition-api'
import { DialogContainer, ConfirmDelete } from 'components'
import { api, moment } from 'plugins'
import { endpoints, getDatesText, setDateRange, showError } from 'utils'

interface SetUp {
  closeDialog: () => void
  isAddNewSuccessful: Ref<boolean>
  createNewNoSaleDate: () => void
  updateNoSaleDate: () => void
  resetDate: () => void
  nosaleDateModal: Ref<boolean>
  nosaleDateStart: Ref<string>
  nosaleDateEnd: Ref<string>
  dateRange: Ref<Array<string>>
  datesText: ComputedRef<string>
  confirmDelete: () => void
  deleteLoading: Ref<boolean>
  loading: Ref<boolean>
  now: Ref<string>
}

const AuctionDateEditDialog = defineComponent({
  components: {
    DialogContainer,
    ConfirmDelete
  },
  props: {
    isShow: {
      type: Boolean,
      required: false
    },
    itemProps: {
      type: Object,
      required: true
    },
    isAddNoSaleDate: {
      type: Boolean,
      required: false
    }
  },
  setup(props, { emit, root }): SetUp {
    const { itemProps } = toRefs(props)
    const { $toast } = root
    const isAddNewSuccessful = ref(false)
    const nosaleDateModal = ref(false)
    const nosaleDateStart = ref('')
    const nosaleDateEnd = ref('')
    const dateRange = ref(['', ''])
    const deleteLoading = ref(false)
    const loading = ref(false)
    const now = ref(moment(new Date()).format('YYYY-MM-DD'))

    const closeDialog = (): void => {
      emit('close-dialog')
    }

    // CREATE case: click save in create Dialog
    const createNewNoSaleDate = async () => {
      // if date range not be selected
      if (dateRange.value[0] === undefined && dateRange.value[1] === undefined) {
        dateRange.value = [
          moment(new Date()).format('YYYY-MM-DD'),
          moment(new Date()).format('YYYY-MM-DD')
        ]
      }
      const data = {
        note: itemProps.value.note || '',
        date_from:
          dateRange.value[0] < dateRange.value[1] ? dateRange.value[0] : dateRange.value[1],
        date_to: dateRange.value[0] < dateRange.value[1] ? dateRange.value[1] : dateRange.value[0],
        is_annual: false
      }
      // if select only date to / date from: set date from = date to
      if (data.date_from === undefined && data.date_to !== undefined) {
        data.date_from = data.date_to
      }
      if (data.date_to === undefined && data.date_from !== undefined) {
        data.date_to = data.date_from
      }
      try {
        loading.value = true
        await api.post(endpoints.NOSALE_DATE, data)
        emit('clear-props')
        emit('on-reload')
        $toast.success(root.$t('master.msg.create_successful'))
        /* eslint-disable no-use-before-define */
        resetDate() // reset data to empty to continue add item
      } catch (e) {
        $toast.error(root.$t('master.msg.date_already_exists'))
        console.log(e)
      } finally {
        loading.value = false
      }
    }

    // EDIT case
    const updateNoSaleDate = async () => {
      const data = {
        note: itemProps.value.note,
        date_from:
          dateRange.value[0] < dateRange.value[1] ? dateRange.value[0] : dateRange.value[1],
        date_to: dateRange.value[0] < dateRange.value[1] ? dateRange.value[1] : dateRange.value[0],
        is_annual: false
      }
      // if select only date to / date from: set date from = date to
      if (data.date_from === undefined && data.date_to !== undefined) {
        data.date_from = data.date_to
      }
      if (data.date_to === undefined && data.date_from !== undefined) {
        data.date_to = data.date_from
      }
      try {
        loading.value = true
        const result = await api.put(`${endpoints.NOSALE_DATE}/${props.itemProps.id}`, data)
        emit('on-reload')
        emit('clear-props')
        if (result === undefined) {
          $toast.error(root.$t('master.msg.update_failed'))
        } else {
          $toast.success(root.$t('master.msg.update_successful'))
        }
      } catch (e) {
        $toast.error(root.$t('master.msg.update_failed'))
        console.log(e)
      } finally {
        loading.value = false
      }
      closeDialog()
    }

    const confirmDelete = async () => {
      deleteLoading.value = true
      try {
        await api.delete(`${endpoints.NOSALE_DATE}/${props.itemProps.id}`)
        $toast.success(root.$t('master.msg.delete_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.delete_failed') as string)
      } finally {
        deleteLoading.value = false
      }
    }

    const resetDate = () => {
      itemProps.value.id = ''
      itemProps.value.date_from = ''
      itemProps.value.date_to = ''
      itemProps.value.note = ''
      itemProps.value.isSelected = false
      dateRange.value = []
    }

    watch(
      () => props.itemProps,
      () => {
        dateRange.value = [
          props.itemProps.date_from,
          props.itemProps.date_to || props.itemProps.date_from
        ]
      }
    )

    watch(
      () => dateRange.value,
      () => {
        const result = setDateRange(dateRange.value)
        nosaleDateStart.value = result.dateStart
        nosaleDateEnd.value = result.dateEnd
        // eslint-disable-next-line prefer-destructuring
        itemProps.value.date_from = dateRange.value[0]
        // eslint-disable-next-line prefer-destructuring
        itemProps.value.date_to = dateRange.value[1]
      }
    )

    // set text of date on input field
    const datesText = computed(() => {
      return getDatesText(root, dateRange.value, nosaleDateStart.value, nosaleDateEnd.value)
    })

    return {
      resetDate,
      closeDialog,
      isAddNewSuccessful,
      createNewNoSaleDate,
      updateNoSaleDate,
      nosaleDateModal,
      nosaleDateStart,
      nosaleDateEnd,
      dateRange,
      datesText,
      confirmDelete,
      deleteLoading,
      loading,
      now
    }
  }
})

export default AuctionDateEditDialog
