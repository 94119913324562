






































































































import { defineComponent } from '@vue/composition-api'
import { getClassOrderType } from 'utils'

import StepButton from '../StepButton/index.vue'

const SingleDetailItem = defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    },
    mark: {
      type: Boolean,
      required: false,
      default: false
    },
    displayButtons: {
      type: Array,
      required: false,
      default: () => []
    },
    hideIconAction: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowBoxType: {
      type: Boolean,
      required: false,
      default: true
    },
    isShowByBox: {
      type: Boolean,
      required: false,
      default: false
    },
    isInputting: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowRemaining: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowTotal: {
      type: Boolean,
      required: false,
      default: true
    },
    page: {
      type: String,
      required: false,
      default: ''
    }
  },
  components: {
    StepButton
  },
  setup(props) {
    const calRemaining = (name: string): number => {
      let value = 0
      if (name === 'order') {
        if (props.isShowByBox) {
          value = props.item.assignBoxes
            ? props.item.orderBoxes - props.item.assignBoxes
            : props.item.orderBoxes
        } else {
          value = props.item.assignStems
            ? props.item.orderStems - props.item.assignStems
            : props.item.orderStems
        }
      } else if (name === 'packing') {
        if (props.isShowByBox) {
          value = props.item.assignBoxes
            ? props.item.packingBoxes - props.item.assignBoxes
            : props.item.packingBoxes
        } else {
          value = props.item.assignStems
            ? props.item.packingStems - props.item.assignStems
            : props.item.packingStems
        }
      } else if (name === 'harvest') {
        if (props.isShowByBox) {
          value = props.item.orderBoxes
            ? props.item.harvestBoxes - props.item.orderBoxes
            : props.item.harvestBoxes
        } else {
          value = props.item.orderStems
            ? props.item.harvestStems - props.item.orderStems
            : props.item.harvestStems
        }
      }
      return value
    }
    return {
      calRemaining,
      getClassOrderType
    }
  }
})

export default SingleDetailItem
