









































































import { defineComponent, Ref, ref, watch } from '@vue/composition-api'

interface SetUp {
  dateStartModal: Ref<boolean>
  dateEndModal: Ref<boolean>
  updateDateStart: () => void
  startDate: Ref<string>
  endDate: Ref<string>
}
const DateRange = defineComponent({
  props: {
    dateStart: {
      type: String,
      required: true
    },
    dateEnd: {
      type: String,
      required: true
    },
    goToDate: {
      type: Function,
      required: false
    }
  },
  setup(props, { root, emit }): SetUp {
    const dateStartModal = ref(false)
    const dateEndModal = ref(false)
    const startDate = ref(props.dateStart)
    const endDate = ref(props.dateEnd)

    if (props.dateStart === props.dateEnd) {
      startDate.value = props.dateStart
      endDate.value = props.dateStart
    } else {
      startDate.value = props.dateStart
      endDate.value = props.dateEnd
    }

    const updateDateStart = () => {
      if (props.dateStart > props.dateEnd) {
        endDate.value = startDate.value
      }
    }

    watch(
      () => props.dateStart,
      () => {
        startDate.value = props.dateStart
      }
    )

    watch(
      () => props.dateEnd,
      () => {
        endDate.value = props.dateEnd
      }
    )

    watch(
      () => startDate.value,
      () => {
        emit('on-change-date', startDate.value, endDate.value)
      }
    )

    watch(
      () => endDate.value,
      () => {
        emit('on-change-date', startDate.value, endDate.value)
      }
    )

    return {
      dateStartModal,
      dateEndModal,
      updateDateStart,
      startDate,
      endDate
    }
  }
})
export default DateRange
