export const TEST = '/test/'
export const HOME = '/home/'
export const TO_YOMI = '/common/get-pronunciation'
export const COMMON = '/common/'
export const ITEMS = '/items/'
export const MATERIAL_ITEMS = '/material_items/'
export const MATERIAL_UNITS = '/material_units/'
export const SIZES = '/sizes/'
export const SIZE_GROUPS = '/size_groups/'
export const QUALITIES = '/qualities/'
export const QUALITY_GROUPS = '/quality_groups/'
export const UNITS = '/units/'
export const IMAGES = '/images/'
export const VARIETIES = '/varieties/'
export const PACKING_RESULTS = '/packing_results/'
export const ASSIGNMENTS = '/assignments/'
export const ORDERS = '/orders/'
export const ORDER_TYPES = '/order_types/'
export const ORDER_DETAILS = '/order_details/'
export const COLORS = '/colors/'
export const CUSTOMERS = '/customers/'
export const GROWER = '/grower/'
export const BOX_TYPE = '/boxtype/'
export const QUICK_INPUT = '/quick_input/'
export const HARVEST_RESULT = '/harvest_results/'
export const AUCTION_DATE = '/auction_dates/'
export const NOSALE_DATE = '/nosale_dates'
export const CUSTOMER_MEMBER = '/customer_member'
export const CUSTOMER_INVOICE = '/customer_invoice'
export const CUSTOMER_GROUP = '/customer_group/'
export const INVOICES = '/invoices/'
export const CLAIM_REGISTER = '/claim/'
export const SETTINGS = '/setting/'
export const NURSERY_COMPANY = '/nursery_company/'
export const SALES_RESULT = '/sales_result/'
export const SALES_RESULT_BREAKDOWN = '/sales_result_breakdown/'
export const SALE_SUMMARY = '/sales_summary/'
export const MEMBER = '/member/'
export const GROWER_DELIVERY = '/grower_delivery/'
export const GROWER_ITEMS = '/grower/items/'
export const GROWER_VARIETIES = '/grower/varieties/'
export const PACKING_IMPORT = '/packing_import/'
export const GROWER_INVOICE_TRANSACTION = '/grower_invoice_transaction/'
export const CLAIM_HANDLING = '/claim_handling/'
export const CUSTOMER_SALES_IMPORT = '/customer_sales/'
