






























































































import { defineComponent, ref, Ref } from '@vue/composition-api'
import { getClassOrderType } from 'utils'

import StepButton from '../StepButton/index.vue'

interface SetUp {
  isShowRemainingOrder: Ref<boolean>
  isShowRemainingHarvest: Ref<boolean>
  isShowRemainingPacking: Ref<boolean>
  isShowRemainingAssign: Ref<boolean>
  calRemaining: (name: string) => void
  getClassOrderType: (number: number | null) => string
}

const MultiLevelItem = defineComponent({
  props: {
    title: {
      type: String,
      required: false
    },
    subTitle: {
      type: String,
      required: false,
      default: ''
    },
    subTitle1: {
      type: String,
      required: false,
      default: ''
    },
    orderTypeId: {
      type: Number,
      required: false,
      default: null
    },
    item: {
      type: Object,
      required: true
    },
    mark: {
      type: Boolean,
      required: false,
      default: true
    },
    hideButtons: {
      type: Array,
      required: false,
      default: () => []
    },
    clickables: {
      type: Array,
      required: false,
      default: () => []
    },
    hideIconAction: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowByBox: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowTotal: {
      type: Boolean,
      required: false,
      default: true
    },
    isShowRemaining: {
      type: Boolean,
      required: false,
      default: false
    },
    page: {
      type: String,
      required: false,
      default: ''
    }
  },
  components: {
    StepButton
  },
  setup(props): SetUp {
    const isShowRemainingOrder: Ref<boolean> = ref(false)
    const isShowRemainingHarvest: Ref<boolean> = ref(false)
    const isShowRemainingPacking: Ref<boolean> = ref(false)
    const isShowRemainingAssign: Ref<boolean> = ref(false)
    const calRemaining = (name: string): number => {
      let value = 0
      if ((props.page === 'harvest' || props.page === 'assign') && name === 'harvest') {
        if (props.isShowByBox) {
          value = props.item.orderBoxes
            ? props.item.harvestBoxes - props.item.orderBoxes
            : props.item.harvestBoxes
        } else {
          value = props.item.orderStems
            ? props.item.harvestStems - props.item.orderStems
            : props.item.harvestStems
        }
        isShowRemainingHarvest.value = props.isShowRemaining
      } else if (props.page === 'home' && name === 'harvest') {
        if (props.isShowByBox) {
          value = props.item.orderedBoxes
            ? props.item.harvestBoxes - props.item.orderedBoxes
            : props.item.harvestBoxes
        } else {
          value = props.item.orderedStems
            ? props.item.harvestStems - props.item.orderedStems
            : props.item.harvestStems
        }
        isShowRemainingHarvest.value = props.isShowRemaining
      } else if (
        (props.page === 'packing-showby-packing' || props.page === 'assign') &&
        name === 'packing'
      ) {
        if (props.isShowByBox) {
          value = props.item.assignedBoxes
            ? props.item.packingBoxes - props.item.assignedBoxes
            : props.item.packingBoxes
        } else {
          value = props.item.assignedStems
            ? props.item.packingStems - props.item.assignedStems
            : props.item.packingStems
        }
        isShowRemainingPacking.value = props.isShowRemaining
      } else if (props.page === 'packing' && name === 'packing') {
        if (props.isShowByBox) {
          value = props.item.assignBoxes
            ? props.item.packingBoxes - props.item.assignBoxes
            : props.item.packingBoxes
        } else {
          value = props.item.assignStems
            ? props.item.packingStems - props.item.assignStems
            : props.item.packingStems
        }
        isShowRemainingPacking.value = props.isShowRemaining
      } else if (props.page === 'home' && name === 'packing') {
        if (props.isShowByBox) {
          value = props.item.assignedBoxes
            ? props.item.packingBoxes - props.item.assignedBoxes
            : props.item.packingBoxes
        } else {
          value = props.item.assignedStems
            ? props.item.packingStems - props.item.assignedStems
            : props.item.packingStems
        }
        isShowRemainingPacking.value = props.isShowRemaining
      } else if (props.page === 'order' && name === 'order') {
        if (props.isShowByBox) {
          value = props.item.assignBoxes
            ? props.item.orderBoxes - props.item.assignBoxes
            : props.item.orderBoxes
        } else {
          value = props.item.assignStems
            ? props.item.orderStems - props.item.assignStems
            : props.item.orderStems
        }
        isShowRemainingOrder.value = props.isShowRemaining
      }
      return value
    }

    return {
      isShowRemainingOrder,
      isShowRemainingHarvest,
      isShowRemainingPacking,
      isShowRemainingAssign,
      getClassOrderType,
      calRemaining
    }
  }
})

export default MultiLevelItem
