













































































import { defineComponent, Ref, ref, toRefs, watch } from '@vue/composition-api'
import { debounce } from 'lodash'
import { convertToWidthCharacter } from 'utils'
import { BoxType, Customer, Item, ObjectWrapper, Quality, Size, Unit } from 'typings'
import MasterItemList from '@/components/MasterItemComponent/MasterItemList.vue'
import ConfirmReset from '@/components/ConfirmReset/index.vue'
import LoaderComponent from '@/components/LoaderComponent/index.vue'
import MasterSpeedDial from '@/components/MasterSpeedDial/index.vue'
import SortActionButton from '@/components/SortActionButton/index.vue'
import MasterBottomSheet from '@/components/MasterBottomSheet/index.vue'

// import { ConfirmReset, LoaderComponent, MasterSpeedDial, SortActionButton } from 'components'

type Combine = Item | Customer | Unit | Size | Quality | BoxType

interface SetUp {
  items: Ref<Array<Combine>>
  searchedItems: Ref<Array<Combine>>
  toggleActionButtonsValue: Ref<boolean>
  searchInfo: Ref<string>
  showConfirmReset: Ref<boolean>
  isSorting: Ref<boolean>
  loading: Ref<boolean>
  search: () => void
  startSorting: () => void
  endSorting: () => void
  updateData: (event: Combine[]) => void
  confirmReset: (param: string) => void
  openDialog: (item: Combine | null) => void
  onSetSeriPriority: () => void
  isSetSeriPriority: Ref<boolean>
  onCheckItem: (dataCheck: Combine) => void
  closeSortAction: () => void
  onSave: () => void
  itemsCheck: Ref<number[]>
}

const MasterItemComponent = defineComponent({
  components: {
    ConfirmReset,
    SortActionButton,
    MasterSpeedDial,
    LoaderComponent,
    MasterItemList,
    MasterBottomSheet
  },
  props: {
    data: {
      type: Array,
      default: () => [],
      required: true
    },
    searchLabelText: {
      type: String,
      default: '',
      required: true
    },
    listEmptyText: {
      type: String,
      default: '',
      required: true
    },
    listSearchEmptyText: {
      type: String,
      default: '',
      required: true
    },
    loadingProp: {
      type: Boolean,
      default: false,
      required: true
    },
    isSortingProp: {
      type: Boolean,
      default: false,
      required: true
    },
    draggable: {
      type: Boolean,
      default: true
    },
    bottomSheetListButton: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  setup(props, { emit }): SetUp {
    const searchInfo = ref('')

    const searchedItems: Ref<Array<Combine>> = ref([])
    const items: Ref<Array<Combine>> = ref([])
    const isSorting = ref(false)
    const isSetSeriPriority = ref(false)
    const toggleActionButtonsValue = ref(false)
    const showConfirmReset = ref(false)
    const loading = ref(false)
    const itemsCheck = ref<number[]>([])
    const { data } = toRefs(props)

    const search = debounce(async () => {
      // include search by halfwidth character (convert to fullwidth)
      const searchText = convertToWidthCharacter(searchInfo.value.trim().toLowerCase(), 'full')
      const searchItemsStartsWith = items.value.filter((item: Combine): boolean => {
        const searchStr = item.searchStr.toLowerCase()
        const regex = new RegExp(`^${searchText}|\\|${searchText}`)
        return regex.test(searchStr)
      })
      const searchItemsIncludes = items.value.filter((item: Combine): boolean => {
        const searchStr = item.searchStr.toLowerCase()
        const regex = new RegExp(`^${searchText}|\\|${searchText}`)
        return !regex.test(searchStr) && searchStr.includes(searchText)
      })
      searchedItems.value = [...searchItemsStartsWith, ...searchItemsIncludes]
    }, 300)

    const saveSortOrder = (): void => {
      const body = items.value.map((item: Combine) => {
        return {
          id: item.id,
          orderNum: item.orderNum
        }
      })
      emit('save-sort-order', body)
    }

    const startSorting = (): void => {
      toggleActionButtonsValue.value = false
      searchInfo.value = ''
      searchedItems.value = [...items.value]
      isSorting.value = true
    }

    const endSorting = (): void => {
      isSorting.value = false
      saveSortOrder()
    }

    const openDialog = (item: Combine | null): void => {
      if (item && isSorting.value === true) {
        return
      }
      emit('open-dialog', item)
    }

    const updateData = (event: ObjectWrapper[]): void => {
      console.log(event)
      // items.value = JSON.parse(JSON.stringify(event))
    }

    const confirmReset = async (param: string) => {
      showConfirmReset.value = false
      if (param === 'reset') {
        emit('confirm-reset')
      }
    }

    const onSave = () => {
      if (isSorting.value) endSorting()
      if (isSetSeriPriority.value) {
        isSetSeriPriority.value = false
        const params = searchedItems.value.filter(
          (e: Combine) => itemsCheck.value.indexOf(e.id) > -1
        )
        params.forEach((p: any) => {
          // eslint-disable-next-line no-param-reassign
          p.customerGroupId = p.customerGroup.id
        })
        emit('on-save-seri-priority', params)
      }
    }

    const closeSortAction = () => {
      isSorting.value = false
      isSetSeriPriority.value = false
      emit('on-reload')
      itemsCheck.value = []
    }

    const onCheckItem = (dataCheck: Combine) => {
      if (!itemsCheck.value.find((e: number) => e === dataCheck.id))
        itemsCheck.value.push(dataCheck.id)
    }

    const onSetSeriPriority = () => {
      isSetSeriPriority.value = true
    }

    watch(data, (value) => {
      items.value = JSON.parse(JSON.stringify(value)) as Array<Combine>
      searchedItems.value = JSON.parse(JSON.stringify(value)) as Array<Combine>
    })

    watch(
      () => props.loadingProp,
      (value) => {
        loading.value = value
      }
    )
    return {
      items,
      toggleActionButtonsValue,
      searchInfo,
      searchedItems,
      showConfirmReset,
      loading,
      isSorting,
      openDialog,
      startSorting,
      endSorting,
      search,
      confirmReset,
      updateData,
      onSetSeriPriority,
      isSetSeriPriority,
      onCheckItem,
      closeSortAction,
      onSave,
      itemsCheck
    }
  }
})

export default MasterItemComponent
