import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import { urlPath, authGuard, navGuard, roleGuard } from 'utils'

Vue.use(VueRouter)

const WIDTH_OF_LAND_IPAD = 768

const routes: Array<RouteConfig> = [
  {
    ...urlPath.START,
    name: 'Top',
    component: () => import(/* webpackChunkName: "start" */ '../pages/Start/index.vue'),
    props: (route) => {
      if (route.hash) {
        return { iFramePath: route.hash }
      }
      return { iFramePath: urlPath.HOME.path }
    },
    beforeEnter: (to, from, next) => {
      if (window.innerWidth < WIDTH_OF_LAND_IPAD) {
        next({ name: 'Home' })
        return
      }
      next()
    }
  },
  {
    ...urlPath.HOME,
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../pages/Home/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.MENU,
    component: () => import(/* webpackChunkName: "menu" */ '../pages/Menu/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.ASSIGN,
    component: () => import(/* webpackChunkName: "assign" */ '../pages/Assign/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.ASSIGN_FORM,
    component: () =>
      import(/* webpackChunkName: "create_or_edit_assign" */ '../pages/AssignForm/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.ASSIGN_GROWER_FORM,
    component: () =>
      import(/* webpackChunkName: "create_or_edit_assign" */ '../pages/Grower/Assign/form.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.ORDER_BY_AUCTION,
    component: () =>
      import(/* webpackChunkName: "order_by_auction_date" */ '../pages/Order/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.ORDER_DETAIL_FORM,
    component: () =>
      import(/* webpackChunkName: "create_order_detail" */ '../pages/Order/form.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.SALES,
    component: () => import(/* webpackChunkName: "sales" */ '../pages/Sales/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.SETTING,
    component: () => import(/* webpackChunkName: "settings" */ '../pages/Setting/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.LOGIN_CALLBACK,
    component: () =>
      import(/* webpackChunkName: "login callback" */ '../pages/LoginCallback/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth < WIDTH_OF_LAND_IPAD) {
        next({ name: 'Top', hash: to.fullPath })
      }
      next()
    }
  },
  {
    ...urlPath.MASTER,
    component: () => import(/* webpackChunkName: "master" */ '../pages/Master/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.QUICK_INPUT,
    component: () =>
      import(/* webpackChunkName: "quick input " */ '../pages/Master/QuickInput/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.MASTER_SIZES,
    component: () => import(/* webpackChunkName: "sizes" */ '../pages/Master/Sizes/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.MASTER_UNITS,
    component: () => import(/* webpackChunkName: "units" */ '../pages/Master/Units/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.MASTER_ITEMS,
    component: () => import(/* webpackChunkName: "items" */ '../pages/Master/Items/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.MASTER_VARIETIES,
    component: () => import(/* webpackChunkName: "items" */ '../pages/Master/Varieties/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.MASTER_CUSTOMERS,
    component: () =>
      import(/* webpackChunkName: "customer" */ '../pages/Master/Customers/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.MASTER_GROWER,
    component: () => import(/* webpackChunkName: "customer" */ '../pages/Master/Grower/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.MASTER_QUALITIES,
    component: () => import(/* webpackChunkName: "items" */ '../pages/Master/Qualities/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.MASTER_MATERIAL,
    component: () => import(/* webpackChunkName: "items" */ '../pages/MaterialMaster/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.MASTER_MATERIAL_ITEMS,
    component: () =>
      import(/* webpackChunkName: "items" */ '../pages/MaterialMaster/Items/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.MASTER_MATERIAL_UNITS,
    component: () =>
      import(/* webpackChunkName: "items" */ '../pages/MaterialMaster/Units/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.PACKING_RESULT_DATE,
    component: () => import(/* webpackChunkName: "items" */ '../pages/PackingResult/date.vue'),
    props: (route) => ({ date: route.query.date }),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.PACKING_RESULT_DETAIL,
    component: () => import(/* webpackChunkName: "items" */ '../pages/PackingResult/detail.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.PACKING_IMPORT,
    component: () => import(/* webpackChunkName: "items" */ '../pages/PackingImport/index.vue'),
    props: (route) => ({ date: route.query.date }),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.PACKING_IMPORT_FORM,
    component: () =>
      import(
        /* webpackChunkName: "create_or_edit_harvest_result" */ '../pages/PackingImport/form.vue'
      ),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.BOXTYPE_LIST,
    component: () => import(/* webpackChunkName: "boxtype" */ '../pages/Master/BoxType/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.HARVEST_RESULT,
    component: () =>
      import(/* webpackChunkName: "harvest_result" */ '../pages/HarvestResult/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.HARVEST_RESULT_FORM,
    component: () =>
      import(
        /* webpackChunkName: "create_or_edit_harvest_result" */ '../pages/HarvestResultForm/index.vue'
      ),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.AUCTION_DATE_SETTING,
    component: () =>
      import(/* webpackChunkName: "auction_date" */ '../pages/Master/AuctionDate/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.MASTER_SETTINGS,
    component: () =>
      import(/* webpackChunkName: "auction_date" */ '../pages/Master/Settings/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.ASSIGN_SERI,
    component: () => import(/* webpackChunkName: "AssignSeri" */ '../pages/AssignSeri/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.ASSIGN_ORDER,
    component: () => import(/* webpackChunkName: "AssignOrder" */ '../pages/AssignOrder/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.INVOICE,
    component: () => import(/* webpackChunkName: "invoice" */ '../pages/Invoice/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.CLAIM_REGISTER,
    component: () => import(/* webpackChunkName: "claim" */ '../pages/ClaimRegister/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.NURSERY_COMPANY_LIST,
    component: () =>
      import(/* webpackChunkName: "nurseryCompany" */ '../pages/Master/NurseryCompany/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.CHILD_GROWER_HOME,
    name: 'Child_grower_home',
    component: () =>
      import(/* webpackChunkName: "childGrowerHome" */ '../pages/ChildGrowerHome/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        let isIncludePermission = false
        urlPath.CHILD_GROWER_PERMISSION.forEach((url: string) => {
          if (window.location.pathname.startsWith(url) && url !== urlPath.START.path) {
            isIncludePermission = true
          }
          if (window.location.pathname === urlPath.START.path) isIncludePermission = true
        })
        if (window.location.pathname === urlPath.HOME.path || isIncludePermission) {
          next()
          return
        }
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.ASSIGN_GROWER,
    component: () =>
      import(/* webpackChunkName: "childGrowerHome" */ '../pages/Grower/Assign/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  },
  {
    ...urlPath.INVOICE_GROWER,
    component: () =>
      import(/* webpackChunkName: "childGrowerHome" */ '../pages/Grower/Invoice/index.vue'),
    beforeEnter: (to, from, next) => {
      if (window.innerWidth >= WIDTH_OF_LAND_IPAD && !('called' in to.query)) {
        next({ name: 'Top', hash: to.fullPath })
        return
      }
      next()
    }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach(authGuard)
router.beforeEach(roleGuard)
router.beforeEach(navGuard)

export default router
